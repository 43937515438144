// Page specific styles
import "../../src/sass/pages/login.sass";

// Global modules
import serverCheck from "../../src/javascript/modules/serverCheck";
import authentication from "../../src/javascript/modules/authentication";

import { localeHandler } from "../../src/javascript/modules/api/locale/localeHandler";

window.server = serverCheck.getServer();

document.addEventListener("DOMContentLoaded", function (event) {
  authentication.init();
  localeHandler.init();
});
