const pageLoaders = {
  displayLoader: function (loaderText) {
    if (this.enabled === false) return;
    if ($(".tms-loader").length != 0) {
      $(".tms-loader").remove();
    }

    const loaderHtml = `
      <div id="spinner" class="tms-loader">
        <div class="content">
          <div class="loader">
            <h4>${loaderText}</h4>

            <div class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
          </div>
        </div>
      </div>
    `;

    $("html").addClass("page-loader");
    $("body").append(loaderHtml);
  },
  removeLoader: function () {
    if ($(".tms-loader").length != 0) {
      $("html").removeClass("page-loader");
      $(".tms-loader").remove();
    }
  },
  displaySmallLoader: function (parentElement, loaderText) {
    if (this.enabled === false) return;
    if (parentElement.find(".small-tms-loader-wrapper").length != 0) {
      parentElement.find(".small-tms-loader-wrapper").remove();
    }

    const smallLoaderHtml = `
      <div class='small-tms-loader-wrapper'>
        <div class='small-tms-loader'>
          <div class='loader'>
            <div>${loaderText}</div>
            <div class='spinner'>
              <div class='rect1'></div>
              <div class='rect2'></div>
              <div class='rect3'></div>
              <div class='rect4'></div>
              <div class='rect5'></div>
            </div>
          </div>
        </div>
      </div>
    `;
    parentElement.append(smallLoaderHtml);
  },

  disable() {
    this.enabled = false;
  },

  enable() {
    this.enabled = true;
  },

  isEnabled() {
    return this.enabled;
  }
};

export default pageLoaders;
