import tailSelect from "tail.select";

export const localeHandler = {
  retrieveLocaleList() {
    return axios.get(
      `${server.serverAdministration}locale/listTranslationCountries`
    );
  },

  buildDropdown() {
    this.retrieveLocaleList().then((response) => {
      const localeArr = response.data.data;
      this.localeObj = localeArr; // to be referenced later

      const options = localeArr.map(
        (localeObj) =>
          `<option value='${localeObj.LOCALE_CODE}'>${localeObj.COUNTRY_NAME}</option>`
      );
      const localeSelectEl = document.querySelector("#locale-select");

      localeSelectEl.innerHTML = options;
      // tailSelect(localeSelectEl, {
      //   multiSelectAll: false,
      //   search: false
      // });

      // Initial state selected lcoale
      const localePassthroughCode = localStorage.getItem("locale"); // optional localeId variable
      if (localePassthroughCode) {
        localeSelectEl.value = localePassthroughCode; // pre-select the locale
        // tailSelect(localeSelectEl).reload();
        this.localeChange(localePassthroughCode); // pre-select the timezone offset applicable to this locale
      } else {
        //tailSelect(localeSelectEl).reload();
        this.localeChange(this.localeObj[0].LOCALE_CODE); // load the first locale in the dropdown by default
      }
    });

    // tailSelect(document.querySelector('#locale-select'), {
    //   placeholder: 'Selected Locale',
    //   search: false,
    //   multiSelectAll: false
    // });
  },

  localeChange(selectedLocaleCode) {
    if (selectedLocaleCode) {
      localStorage.setItem("locale", selectedLocaleCode);
      this.getTranslationsByLocale(selectedLocaleCode);
    }
  },

  replaceTranslations() {
    const translatables = document.querySelectorAll(".trans");
    for (const translatable of translatables) {
      const transKey = translatable.dataset.transKey;
      const eleType = translatable.nodeName.toUpperCase();
      switch (eleType) {
        case "INPUT":
          translatable.placeholder = localeHandler.getTranslation(transKey);
          break;
        default:
          translatable.innerHTML = localeHandler.getTranslation(transKey);
          break;
      }
    }
  },

  getTranslationsByLocale(selectedLocaleCode) {
    const locale = selectedLocaleCode || localStorage.getItem("locale");
    if (locale) {
      axios
        .get(
          `${server.serverAdministration}translation/text/list/${locale}`
          //config
        )
        .then(function (localeResponse) {
          if (
            localeResponse &&
            localeResponse.data &&
            localeResponse.data.data
          ) {
            localStorage.setItem(
              "translations",
              JSON.stringify(localeResponse.data.data)
            );
            localeHandler.replaceTranslations();

            const loginPageBodyEl = document.querySelector("body.login-page");
            const loadTranslationEl = document.createElement("div");
            loadTranslationEl.innerHTML = "Translations Loaded";
            loadTranslationEl.setAttribute(
              "data-test-id",
              "translation-loaded"
            );
            loadTranslationEl.classList.add("d-none");
            loginPageBodyEl.append(loadTranslationEl);
          }
        })
        .catch(function (error) {
          console.error("Error Getting Translations");
          console.error(error);
        });
    }
  },

  getTranslation(translationKey) {
    const translations = JSON.parse(localStorage.getItem("translations"));
    const locale = localStorage.getItem("locale") || "en-GB";

    let translation = "";
    if (
      translations &&
      translations[translationKey] &&
      translations[translationKey][locale] &&
      translations[translationKey][locale]["TRANSLATION_TEXT"]
    ) {
      // use correct key and locale
      translation = translations[translationKey][locale]["TRANSLATION_TEXT"];
    } else if (
      translations &&
      translations[translationKey] &&
      translations[translationKey]["en-GB"] &&
      translations[translationKey]["en-GB"].TRANSLATION_TEXT
    ) {
      // fallback to en-GB if available
      translation = translations[translationKey]["en-GB"].TRANSLATION_TEXT;
    } else {
      // let the FE know they're using an invalid key
      translation = `Invalid translationKey: ${translationKey}`;
    }

    return translation;
  },

  eventHandlers() {
    // change of select and click of save button
    const localeSelect = document.querySelector("#locale-select");
    if (localeSelect) {
      localeSelect.addEventListener("change", (e) => {
        const selectedOptionValue = e.currentTarget.selectedOptions[0].value;
        this.localeChange(selectedOptionValue);
      });
    } else {
      const locale = localStorage.getItem("locale") || "en-GB";
      this.localeChange(locale);
    }
  },

  init() {
    this.buildDropdown();
    this.eventHandlers();
  }
};
