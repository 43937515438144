import { merge } from "lodash";

const FEATURES = {
  avalara: "avalara",
  hubspot: "hubspot",
  hubspotLinkCustomer: "hubspotLinkCustomer",
  stripePaymentLinks: "stripePaymentLinks"
};

const DEFAULT_FEATURES = {
  [FEATURES.avalara]: false,
  [FEATURES.hubspot]: false,
  [FEATURES.hubspotLinkCustomer]: false
};

class FeatureToggleService {
  _availableFeatures = Object.values(FEATURES);

  /** @private */
  storageKey = "tms-feature-toggle-v2";

  /** @private */
  animationFrame = 0;

  constructor(features) {
    /** @type {Map<string, boolean>} */
    this.features = new Map(
      Object.entries(merge({}, DEFAULT_FEATURES, features))
    );
  }

  save() {
    cancelAnimationFrame(this.animationFrame);
    this.animationFrame = requestAnimationFrame(() => {
      localStorage.setItem(
        this.storageKey,
        JSON.stringify(Object.fromEntries(this.features.entries()))
      );
    });
  }

  load(features) {
    const stored = localStorage.getItem(this.storageKey);
    let storedFeaturesJSON = {};
    if (stored) {
      try {
        storedFeaturesJSON = JSON.parse(stored);
      } catch (error) {
        console.error(error);
      }
    }

    this.features = new Map(
      Object.entries(merge({}, DEFAULT_FEATURES, storedFeaturesJSON, features))
    );
  }

  /**
   * @param {keyof FEATURES} feature
   */
  enable(feature) {
    this.set(feature, true);
  }

  /**
   * @param {keyof FEATURES} feature
   */
  disable(feature) {
    this.set(feature, false);
  }

  /**
   * @param {keyof FEATURES} feature
   * @param {boolean} enabled
   */
  set(feature, enabled) {
    this.features.set(feature, enabled);
    this.save();
  }

  /**
   * @param {keyof FEATURES} feature
   */
  isEnabled(feature) {
    return this.features.get(feature);
  }
}

const featureToggleService = new FeatureToggleService();
featureToggleService.load();

window.featureToggleService = featureToggleService;

export default featureToggleService;
