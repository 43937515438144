import pageLoader from "./pageLoaders";
import moment from "moment";
import serverCheck from "./serverCheck";
import featureToggleService from "../services/featureToggleService";

const server = serverCheck.getServer();

// This file is for authentication
const authentication = {
  pageUrlParams(param) {
    const returnedParam = new URL(window.location.href).searchParams.get(param);

    return returnedParam;
  },

  buildUserSession(data) {
    // build User session
    let user = {
      userId: null,
      email: null,
      firstName: null,
      lastName: null,
      token: null,
      roles: null,
      timezone: null,
      preferences: null
    };

    // Assign email
    if (data.email) {
      Object.assign(user, { email: data.email });
    }

    // Assign first name
    if (data.firstName) {
      Object.assign(user, { firstName: data.firstName });
    }

    // Assign last name
    if (data.lastName) {
      Object.assign(user, { lastName: data.lastName });
    }

    // Assign roles
    if (data.roles) {
      Object.assign(user, { roles: data.roles });
    }

    // User id
    if (data.userId) {
      Object.assign(user, { userId: data.userId });
    }

    // Assign token
    if (data.token) {
      Object.assign(user, { token: data.token });
    }

    // Assign timezone
    if (data.timezone) {
      Object.assign(user, { timezone: data.timezone });
    }

    // Assign preferences
    if (data.preferences) {
      Object.assign(user, { preferences: data.preferences });
    }

    if (data.features) {
      Object.assign(user, { features: data.features });
      featureToggleService.load(data.features);
      featureToggleService.save();
    }

    sessionStorage.setItem("user", JSON.stringify(user));
  },

  sessions(response) {
    // Remove sessions on login
    // this.removeUserSession();

    if (response.branches) {
      sessionStorage.setItem("branches", JSON.stringify(response.branches));
    }

    const permissions = response.permissions;

    if (permissions) {
      sessionStorage.setItem("user_permissions", JSON.stringify(permissions));
    }

    const roles = response.roles;

    if (roles) {
      sessionStorage.setItem("user_role", JSON.stringify(roles));
    }

    this.buildUserSession(response);
  },

  removeUserSession() {
    sessionStorage.clear();
    localStorage.clear();

    pageLoader.displayLoader("Logging out...");

    setTimeout(() => location.replace("/"), 1000);
  },

  checkToken() {
    if (!this.getDetails("token")) {
      window.location.href = "/error-pages/unauthorised";
    }
  },

  checkExpired() {
    const url = "/";
    if (location.pathname === url) return;
    const userToken = this.getDetails("token");
    if (!userToken) location.href = url;
    const token = this.userTokenDecoder(userToken);
    const exp = new Date(parseInt(token.exp) * 1000);

    if (moment().isAfter(exp)) {
      location.href = url;
    }
  },

  jsonParseSession(session) {
    if (session) {
      return JSON.parse(session);
    }

    return null;
  },

  getDetails(type) {
    const branches = this.jsonParseSession(sessionStorage.getItem("branches"));
    const user = this.jsonParseSession(sessionStorage.getItem("user"));
    const user_permissions = this.jsonParseSession(
      sessionStorage.getItem("user_permissions")
    );
    const user_role = this.jsonParseSession(
      sessionStorage.getItem("user_role")
    );

    let returnDetail = "";

    if (!user) {
      return false;
    }

    switch (type) {
      case "id":
        // Get user id helper
        returnDetail = user.userId || null;
        break;

      case "role":
        // Get user role helper
        returnDetail = user.roles || null;
        break;

      case "name":
        // Get user name helper
        returnDetail = `${user.firstName || ""} ${user.lastName || ""}`;
        break;

      case "token":
        // Get user token helper
        returnDetail = user.token || null;
        break;

      case "user_role":
        returnDetail = user_role[0].id || null;
        break;

      case "user_permissions":
        returnDetail = user_permissions || null;
        break;
    }

    return returnDetail;
  },

  userTokenDecoder(token) {
    const tokenBase64 = token.split(".")[1];
    const base64 = tokenBase64.replace(/-/g, "+").replace(/_/g, "/");

    const tokenData = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(tokenData);
  },

  loginHandler() {
    const loginBtn = document.querySelector(".js-user-login");
    if (loginBtn) {
      loginBtn.addEventListener("click", function () {
        window.location.replace(
          `${server.serverLoginUrl}/oauth2/authorize?identity_provider=SSO&client_id=${server.serverLoginClientId}&response_type=token&scope=email+openid+phone&redirect_uri=${window.location.origin}/login-callback/`
        );
      });
    }
  },

  logoutHandler() {
    let _this = this;

    const logoutBtns = document.querySelectorAll(".js-user-logout");

    for (const logoutBtn of logoutBtns) {
      logoutBtn.addEventListener("click", function () {
        _this.logout();
        return false;
      });
    }
  },

  logout() {
    this.removeUserSession();
  },

  eventHandlers() {
    this.loginHandler();
    this.logoutHandler();
  },

  init() {
    this.eventHandlers();
  }
};

export default authentication;
